@tailwind base;
@tailwind components;
@tailwind utilities;

.signin-page {
  background: url('../public/sign-in-bg.jpg') no-repeat center;
  background-size: cover;
}

.signup-page {
  background: url('../public/sign-up-bg.jpg') no-repeat center;
  background-size: cover;
}